import React from 'react';

// Components

import './PreOrder.css';

const PreOrder = (props) => {

  return (
    <div className='featured-video-div'>
      <div className='featured-video-header-div'>
        <h2>LISTEN / ORDER NOW</h2>
      </div>
      
    </div>
  );
};

export default PreOrder;